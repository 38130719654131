<!-- 检验数据 -->
<template>
  <div class="preinspdata">
    <!-- 膝关节弹窗 -->
    <el-dialog
      :title="'请填写' + dialogTitle[next - 1]"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      :width="diawid"
    >
      <el-progress :percentage="percentage"></el-progress>
      <commonpreinspdata
      :operateForm="operateForm"
      :formData="formData"
      :next="next"
    />
      <span slot="footer" class="dialog-footer">
        <el-button @click="backlist" type="warning">上一步</el-button>
        <el-button @click="nextlist" type="primary" v-show="nextBtn"
          >下一步</el-button
        >
        <el-button type="danger" @click="closedialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <commonpreinspdata
      :operateForm="operateForm"
      :formData="formData"
      :showAll="showAll"
      :modify="modify"
      v-if="!showHip"
    />

    <el-button type="primary" @click="goFillin" v-show="showBtn"
      >去填写</el-button
    >
    <div class="modifyBtn" v-show="!showBtn">
      <!-- <el-button type="warning" @click="toModify" v-show="modify"
        >修改</el-button
      > -->
      <el-button type="primary" icon="el-icon-edit" circle @click="toModify" v-show="modify"></el-button>
      <div v-show="!modify" >
        <el-row>
          <el-button type="success" icon="el-icon-check" circle @click="sure"></el-button>
        </el-row>
        <el-row>
          <el-button type="danger" icon="el-icon-close" circle @click="cancel"></el-button>
        </el-row>
      </div>
      <!-- <el-button type="success" @click="sure" v-show="!modify">确定</el-button>
      <el-button type="danger" @click="cancel" v-show="!modify">取消</el-button> -->
    </div>
  </div>

  
</template>

<script>
import commonpreinspdata from './common-preinspdata.vue';
export default {
  name:'preinspdata',
  components:{
    commonpreinspdata
  },
  data () {
    return {
      operation_id: "",
      operation_site:"",
      showHip:false,
      next: 1,
      showAll: false,
      dialogVisible:false,
      hipDialogVisible:false,
      modify: true,
      percentage: 11.11,
      hipPercentage:7.14,
      showBtn: true,
      nextBtn: true,
      diawid:"90%",
      dialogTitle:["血常规","尿常规","中断尿细菌涂片","生化","炎症指标","凝血功能","脂代谢","骨标志物","25OH-VD"],
      dialogHipTitle:["血常规","尿常规","中断尿细菌涂片","生化","炎症指标","凝血功能","脂代谢","骨标志物","25OH-VD"],
      // 传给后端的数据
      operateForm: {
        blood_routine: {
          abo: "",
          rh: "",
          wbc: "",
          n: "",
          hb: "",
          plt: "",
        },
        urine_routine: {
          wbc: 0,
          wbcoscopy: "",
          germoscopy: "",
        },
        smear: {
          gpc: "",
          gnc: "",
          gnr: "",
          gm: "",
        },
        bmp: {
          alb: "",
          k: "",
          na: "",
          cl: "",
          alt: "",
          ast: "",
          cr: "",
          urea: "",
        },
        inflammation: {
          il: "",
          pct: "",
          esr: "",
          crp: "",
        },
        coagulation: {
          tt: "",
          pt: "",
          aptt: "",
          fib: "",
          fdp: "",
          dd: "",
        },
        lipid_metabolism: {
          tc: "",
          tg: "",
          hdl: "",
          ldl: "",
        },
        pinp: {
          ct: "",
          osteoc: "",
          tp1np: "",
          ipth: "",
          hvd: "",
        },
        
      },
      // 配置数据
      formData: {
        blood: [
          {
            label: "ABO血型",
            model: "abo",
            type: "radio",
            list: ["A", "B", "O", "AB"],
          },
          {
            label: "RH血型",
            model: "rh",
            type: "radio",
            list: ["RH+", "RH-"],
          },
          {
            model: "wbc",
            type: "input",
            label: "WBC",
            unit: "10⁹/L",
          },
          {
            model: "n",
            type: "input",
            label: "N",
            unit: "10⁹/L",
          },
          {
            model: "hb",
            type: "input",
            label: "HB",
            unit: "g/L",
          },
          {
            model: "plt",
            type: "input",
            label: "PLT",
            unit: "10⁹/L",
          },
        ],
        urine: [
          {
            model: "wbc",
            label: "尿白细胞",
            hasUnit:false,
          },
          {
            model: "wbcoscopy",
            label: "镜检白细胞",
            unit: "/μl",
            hasUnit:true,
          },
          {
            model: "germoscopy",
            label: "镜检细菌",
            unit: "/μl",
            hasUnit:true,
          },
        ],
        smear: [
          {
            model: "gpc",
            label: "G+球菌",
            list: ["未见", "偶见","微量", "少量", "大量"],
          },
          {
            model: "gnc",
            label: "G-球菌",
            list: ["未见", "偶见","微量", "少量", "大量"],
          },
          {
            model: "gnr",
            label: "G+杆菌",
            list: ["未见", "偶见","微量", "少量", "大量"],
          },
          {
            model: "gm",
            label: "G-杆菌",
            list: ["未见", "偶见","微量", "少量", "大量"],
          },
        ],
        inputParams: [
          {
            span: "生化",
            next: 4,
            obj: "bmp",
            showUnit: true,
            opt: [
              {
                model: "alb",
                label: "ALB",
                unit: "g/L",
              },
              {
                model: "k",
                label: "K+",
                unit: "mmol/L",
              },
              {
                model: "na",
                label: "Na+",
                unit: "mmol/L",
              },
              {
                model: "cl",
                label: "Cl-",
                unit: "mmol/L",
              },
              {
                model: "alt",
                label: "ALT",
                unit: "U/L",
              },
              {
                model: "ast",
                label: "AST",
                unit: "U/L",
              },
              {
                model: "cr",
                label: "CR",
                unit: "μmol/L",
              },
              {
                model: "urea",
                label: "Urea",
                unit: "mmol/L",
              },
            ],
          },
          {
            span: "炎症指标",
            next: 5,
            obj: "inflammation",
            showUnit: true,
            opt: [
              {
                model: "il",
                label: "IL-6",
                unit: "pg/ml",
              },
              {
                model: "pct",
                label: "PCT",
                unit: "ug/L",
              },
              {
                model: "esr",
                label: "ESR",
                unit: "mm/h",
              },
              {
                model: "crp",
                label: "CRP",
                unit: "mg/L",
              },
            ],
          },
          {
            span: "凝血功能",
            next: 6,
            obj: "coagulation",
            showUnit: true,
            opt: [
              {
                model: "tt",
                label: "TT",
                unit: "s",
              },
              {
                model: "pt",
                label: "PT",
                unit: "s",
              },
              {
                model: "aptt",
                label: "APTT",
                unit: "s",
              },
              {
                model: "fib",
                label: "FIB",
                unit: "g/L",
              },
              {
                model: "fdp",
                label: "FDP",
                unit: "μg/mL",
              },
              {
                model: "dd",
                label: "D-D",
                unit: "μg/L",
              },
            ],
          },
          {
            span: "脂代谢",
            next: 7,
            obj: "lipid_metabolism",
            showUnit: true,
            opt: [
              {
                model: "tc",
                label: "TC",
                unit: "mmol/L",
              },
              {
                model: "tg",
                label: "TG",
                unit: "mmol/L",
              },
              {
                model: "hdl",
                label: "HDL",
                unit: "mmol/L",
              },
              {
                model: "ldl",
                label: "LDL",
                unit: "mmol/L",
              },
            ],
          },
          {
            span: "骨标志物",
            next: 8,
            obj: "pinp",
            showUnit: true,
            opt: [
              {
                model: "ct",
                label: "β-CT",
                unit: "pg/mL",
              },
              {
                model: "osteoc",
                label: "OSTEOC",
                unit: "pg/mL",
              },
              {
                model: "tp1np",
                label: "TP1NP",
                unit: "pg/mL",
              },
              {
                model: "ipth",
                label: "iPTH",
                unit: "pg/mL",
              },
            ],
          },
          {
            span: "25OH-VD",
            next: 9,
            obj: "pinp",
            showUnit: true,
            opt: [
              {
                model: "hvd",
                label: "25OH-VD",
                unit: "ng/mL",
              }
            ]
          },
        ]
      }
    }
  },
  
  created(){
    this.operation_id = this.$route.query.room;
    this.operation_site = this.$route.query.site;
    this.GetPreInspDataInfo();
  },

  computed: {
    percent(){
      return Number(this.percentage.toFixed(2))
    },
    hipPercent(){
      return Number(this.hipPercentage.toFixed(2))
    }
  },
  methods:{
    // 获取表单请求
    async GetPreInspDataInfo() {
      try {
        const { data: res } = await this.$http.get(
          "/Periop/getPeriopPreop/" + this.operation_id,
        );
          // console.log(res,"围手术期数据获取成功");

        if (res.status == 0) {
          this.showBtn = true
        }else if (res.status == 1) {
          let value = res.data;
            for(let key in value){
              this.operateForm[key] = value[key] ? value[key] : this.operateForm[key]
          }
          // this.operateForm = res.data
          this.showAll = true;
          this.showBtn = false;
          this.loading = false
          console.log(res,"围手术期数据获取成功");
        }
      } catch (error) {
        // console.log(error);
      }
    },
    // 提交表單請求
    async postPreInspDataInfo(){
      try {
        const {PPreop_id,...objRest} = this.operateForm
        const { data: res } = await this.$http.post(
          "/Periop/addPeriopPreop/" + this.operation_id,
          objRest
        );
        console.log(res, "写入成功");
        if (res.status == 1) {
         this.$message.success("修改成功")
         this.GetPreInspDataInfo()
         
        }
      } catch (error) {
        alert(error.message)
      }
    },
    // 去填写
    goFillin() {
      if (this.operation_site == "髋") {
        this.hipDialogVisible = true;
      }else{
        this.dialogVisible = true;
      }
    },
    toModify() {
      this.modify = !this.modify;
    },
    // 点击确定修改
    sure() {
      this.modify = !this.modify;
      this.postPreInspDataInfo();
    },
    // 点击取消修改
    cancel() {
      this.modify = !this.modify;
    },
    // 点击填写下一步
    nextlist() {
      this.next = this.next < 9 ? this.next + 1 : 9;
      if (this.next < 9) {
        this.percentage += 11.11;
      } else {
        this.percentage = 100;
        this.nextBtn = false;
      }
    },
    // 上一步
    backlist() {
      this.next = this.next - 1 <= 1 ? 1 : this.next - 1;
      this.percentage = this.percentage > 12 ? this.percentage - 11.11 : 12;
      this.nextBtn = this.next > 8 ? false : true;
    },
    // 髋关节填写下一步
    hipnextlist() {
      this.next = this.next < 14 ? this.next + 1 : 14;
      if (this.next < 14) {
        this.hipPercentage += 7.14;
      } else {
        this.hipPercentage = 100;
        this.nextBtn = false;
      }
    },
    // 髋关节上一步
    hipbacklist() {
      this.next = this.next - 1 <= 1 ? 1 : this.next - 1;
      this.hipPercentage = this.hipPercentage > 7.18 ? this.hipPercentage - 7.14 : 7;
      this.nextBtn = this.next > 13 ? false : true;
    },
    // dialog确定
    closedialog() {
       this.postPreInspDataInfo()
      this.dialogVisible = false;
      this.hipDialogVisible = false;
      this.showBtn = false;
      this.showAll = true;
    },
  }
}
</script>

<style scoped>
.preinspdata{
  box-sizing: border-box;
  /* border:1px solid red; */

}
.preinspdata .modifyBtn{
  position: absolute;
  right:15%;
  top: 60%;
  z-index: 9999;
  /* box-sizing: border-box; */

}
@media screen and (max-width: 600px) {
  .preinspdata .modifyBtn {
    top: 75%;
    right: 3%;
 
}
}
.preinspdata .el-progress-bar{
  margin-right: -60px !important;
}
</style>