<template>
  <div class="precheckdata">
    <!-- 膝关节弹窗 -->
    <el-dialog
      :title="'请填写' + dialogTitle[next - 1]"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      :width="diawid"
    >
      <el-progress :percentage="percent"></el-progress>
      <commonprecheckdata
      :operateForm="operateForm"
      :formData="formData"
      :next="next"
    />
      <span slot="footer" class="dialog-footer">
        <el-button @click="backlist" type="warning">上一步</el-button>
        <el-button @click="nextlist" type="primary" v-show="nextBtn"
          >下一步</el-button
        >
        <el-button type="danger" @click="closedialog" 
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 髋关节弹窗 -->
    <el-dialog
      :title="'请填写' + dialogHipTitle[next - 1]"
      :visible.sync="hipDialogVisible"
      v-if="hipDialogVisible"
      :width="diawid"
    >
      <el-progress :percentage="hipPercent"></el-progress>
      <commonprecheckdatahip
      :operateForm="operateForm"
      :formData="formData"
      :next="next"
    />
      <span slot="footer" class="dialog-footer">
        <el-button @click="hipbacklist" type="warning">上一步</el-button>
        <el-button @click="hipnextlist" type="primary" v-show="nextBtn"
          >下一步</el-button
        >
        <el-button type="danger" @click="closedialog" v-show="this.next == 5"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <commonprecheckdata
      :operateForm="operateForm"
      :formData="formData"
      :showAll="showAll"
      :modify="modify"
      v-if="!showHip"
    />

    <commonprecheckdatahip
      :operateForm="operateForm"
      :formData="formData"
      :showAll="showAll"
      :modify="modify"
      :operation_site="operation_site"
      v-if="showHip"
    />

    <el-button type="primary" @click="goFillin" v-show="showBtn"
      >去填写</el-button
    >
    <div class="modifyBtn" v-show="!showBtn">
      <!-- <el-button type="warning" @click="toModify" v-show="modify"
        >修改</el-button
      > -->
      <el-button type="primary" icon="el-icon-edit" circle @click="toModify" v-show="modify"></el-button>
      <div v-show="!modify" >
        <el-row>
          <el-button type="success" icon="el-icon-check" circle @click="sure"></el-button>
        </el-row>
        <el-row>
          <el-button type="danger" icon="el-icon-close" circle @click="cancel"></el-button>
        </el-row>
      </div>
      <!-- <el-button type="success" @click="sure" v-show="!modify">确定</el-button>
      <el-button type="danger" @click="cancel" v-show="!modify">取消</el-button> -->
    </div>
  </div>
</template>

<script>
import commonprecheckdata from "./common-precheckdata.vue";
import commonprecheckdatahip from './common-precheckdata-hip.vue';
export default {
  name: "precheckdata",
  components: {
    commonprecheckdata,
    commonprecheckdatahip
  },
  data() {
    return {
      operation_id: "",
      operation_site: "",
      showHip: false,
      next: 1,
      showAll: false,
      dialogVisible: false,
      hipDialogVisible: false,
      modify: true,
      percentage: 7.69,
      hipPercentage: 20,
      showBtn: true,
      nextBtn: true,
      diawid: "90%",
      dialogTitle: [
        "正位脊柱",
        "股骨",
        "X线检查",
        "CT测量",
        "规划",
        "MRI-骨髓损伤",
        "关节软骨",
        "关节周征象",
        "韧带/肌腱",
        "骨赘",
        "半月板位置",
        "半月板形态和信号异常",
        "彩超",
      ],
      dialogHipTitle: [
        "正位脊柱",
        "股骨",
        "X线检查",
        "CT测量",
        "彩超",
      ],
      // 传给后端的数据
      operateForm: {
        orth_spine: {
          l1: "",
          l2: "",
          l3: "",
          l4: "",
          l5: "",
        },
        femur: {
          neck_femur: "",
          wards: "",
          tuberosity: "",
          all_femur: "",
        },
        xray: [
          {
            name: "MPTA",
            pre: "",
            after: "",
          },
          {
            name: "LDFA",
            pre: "",
            address: "",
          },
          {
            name: "JLO",
            pre: "",
            after: "",
          },
          {
            name: "aHKA",
            pre: "",
            after: "",
          },
          {
            name: "mHKA",
            pre: "",
            after: "",
          },
          {
            name: "JLCA",
            pre: "",
            after: "",
          },
          {
            name: "MNSA",
            pre: "",
            after: "",
          },
          {
            name: "VCA",
            pre: "",
            after: "",
          },
          {
            name: "TAMA",
            pre: "",
            after: "",
          },
          {
            name: "aFTA",
            pre: "",
            after: "",
          },
          {
            name: "aLDTA",
            pre: "",
            after: "",
          },
          {
            name: "LPFA",
            pre: "",
            after: "",
          },
          {
            name: "CPAK",
            pre: "",
            after: "",
          },
        ],
        ct: [
          {
            name: "AMA",
            left: "",
            right: "",
            after: "",
          },
          {
            name: "LDFA",
            left: "",
            right: "",
            after: "",
          },
          {
            name: "MNSA",
            left: "",
            right: "",
            after: "",
          },
          {
            name: "mTFA",
            left: "",
            right: "",
            after: "",
          },
          {
            name: "aTFA",
            left: "",
            right: "",
            after: "",
          },
          {
            name: "PCA",
            left: "",
            right: "",
            after: "",
          },
          {
            name: "MPTA",
            left: "",
            right: "",
            after: "",
          },
          {
            name: "FNA",
            left: "",
            right: "",
            after: "",
          },
          {
            name: "PTS",
            left: "",
            right: "",
            after: "",
          },
        ],
        plan: [
          { name: "MDF", plan: "", mid: "" },
          { name: "LDF", plan: "", mid: "" },
          { name: "MPF", plan: "", mid: "" },
          { name: "LPF", plan: "", mid: "" },
          { name: "MFT", plan: "", mid: "" },
          { name: "LFT", plan: "", mid: "" },
          { name: "MPT", plan: "", mid: "" },
          { name: "LPT", plan: "", mid: "" },
        ],
        mri: [
          {
            id: 1,
            name: "股骨",
            region: "外侧滑车区",
            subregion: "",
            damage: "",
            count: "",
          },
          {
            id: 1,
            name: "股骨",
            region: "外侧中央区",
            subregion: "",
            damage: "",
            count: "",
          },
          {
            id: 1,
            name: "股骨",
            region: "外侧后区",
            subregion: "",
            damage: "",
            count: "",
          },
          {
            id: 1,
            name: "股骨",
            region: "内侧滑车区",
            subregion: "",
            damage: "",
            count: "",
          },
          {
            id: 1,
            name: "股骨",
            region: "内侧中央区",
            subregion: "",
            damage: "",
            count: "",
          },
          {
            id: 1,
            name: "股骨",
            region: "内侧后区",
            subregion: "",
            damage: "",
            count: "",
          },

          {
            id: 2,
            name: "胫骨",
            region: "外侧前区",
            subregion: "",
            damage: "",
            count: "",
          },
          {
            id: 2,
            name: "胫骨",
            region: "外侧中央区",
            subregion: "",
            damage: "",
            count: "",
          },
          {
            id: 2,
            name: "胫骨",
            region: "外侧后区",
            subregion: "",
            damage: "",
            count: "",
          },
          {
            id: 2,
            name: "胫骨",
            region: "内侧前区",
            subregion: "",
            damage: "",
            count: "",
          },
          {
            id: 2,
            name: "胫骨",
            region: "内侧中央区",
            subregion: "",
            damage: "",
            count: "",
          },
          {
            id: 2,
            name: "胫骨",
            region: "内侧后区",
            subregion: "",
            damage: "",
            count: "",
          },
          {
            id: 2,
            name: "胫骨",
            region: "棘突上区",
            subregion: "",
            damage: "",
            count: "",
          },

          {
            id: 3,
            name: "髌骨",
            region: "髌骨内侧",
            subregion: "",
            damage: "",
            count: "",
          },
          {
            id: 3,
            name: "髌骨",
            region: "髌骨外侧",
            subregion: "",
            damage: "",
            count: "",
          },
        ],
        cartilage: [
          { id: 1, name: "股骨", region: "外侧前区", all: "", part: "" },
          { id: 1, name: "股骨", region: "外侧中央区", all: "", part: "" },
          { id: 1, name: "股骨", region: "外侧后区", all: "", part: "" },
          { id: 1, name: "股骨", region: "内侧后区", all: "", part: "" },
          { id: 1, name: "股骨", region: "内侧中央区", all: "", part: "" },
          { id: 1, name: "股骨", region: "内侧后区", all: "", part: "" },

          { id: 2, name: "胫骨", region: "外侧前区", all: "", part: "" },
          { id: 2, name: "胫骨", region: "外侧中央区", all: "", part: "" },
          { id: 2, name: "胫骨", region: "外侧后区", all: "", part: "" },
          { id: 2, name: "胫骨", region: "内侧后区", all: "", part: "" },
          { id: 2, name: "胫骨", region: "内侧中央区", all: "", part: "" },
          { id: 2, name: "胫骨", region: "内侧后区", all: "", part: "" },

          { id: 3, name: "髌骨", region: "髌骨内侧", all: "", part: "" },
          { id: 3, name: "髌骨", region: "髌骨外侧", all: "", part: "" },
        ],
        hoffa: "",
        arthrohydrops: "",
        joint_signs: {
          sign1: "",
          sign2: "",
          sign3: "",
          sign4: "",
          sign5: "",
          sign6: "",
          sign7: "",
          sign8: "",
        },
        ligament_tendon: {
          tendon1: "",
          tendon2: "",
          tendon3: "",
        },
        osteophyte: {
          region1: "",
          region2: "",
          region3: "",
          region4: "",
          region5: "",
          region6: "",
          region7: "",
          region8: "",
          region9: "",
          region10: "",
          region11: "",
          region12: "",
        },
        meniscus_position: {
          position1: "",
          position2: "",
          position3: "",
          position4: "",
        },
        shape_signal: [
          { id: 1, name: "内侧", region: "前角", signs: "" },
          { id: 1, name: "内侧", region: "体部", signs: "" },
          { id: 1, name: "内侧", region: "后角", signs: "" },

          { id: 2, name: "外侧", region: "前角", signs: "" },
          { id: 2, name: "外侧", region: "体部", signs: "" },
          { id: 2, name: "外侧", region: "后角", signs: "" },
        ],
        cardiac: "",
        dvt: [],
      },

      // 配置数据
      formData: {
        inputParams: [
          {
            span: "正位脊柱",
            next: 1,
            obj: "orth_spine",
            showUnit: false,
            opt: [
              {
                model: "l1",
                label: "L1",
              },
              {
                model: "l2",
                label: "L2",
              },
              {
                model: "l3",
                label: "L3",
              },
              {
                model: "l4",
                label: "L4",
              },
              {
                model: "l5",
                label: "L1-4",
              },
            ],
          },
          {
            span: "股骨",
            next: 2,
            obj: "femur",
            showUnit: false,
            opt: [
              {
                model: "neck_femur",
                label: "股骨颈 ",
              },
              {
                model: "wards	",
                label: "Wards三角",
              },
              {
                model: "tuberosity",
                label: "大粗隆",
              },
              {
                model: "all_femur",
                label: "全部",
              },
            ],
          },
        ],
        hoffa_arthrohydrops: [
          {
            model: "hoffa",
            label: "滑膜炎(Hoffa滑膜炎)",
            list: ["0", "1", "2", "3"],
          },
          {
            model: "arthrohydrops",
            label: "关节积液(渗出-滑膜炎)",
            list: ["0", "1", "2", "3"],
          },
        ],
        joint_signs: [
          { model: "sign1", label: "髂胫束异常信号", list: ["0", "1"] },
          { model: "sign2", label: "鹅组滑囊炎", list: ["0", "1"] },
          { model: "sign3", label: "腱鞘", list: ["0", "1"] },
          { model: "sign4", label: "游离体", list: ["0", "1"] },
          { model: "sign5", label: "髌下囊信号异常", list: ["0", "1"] },
          { model: "sign6", label: "腘窝囊肿", list: ["0", "1"] },
          { model: "sign7", label: "囊肿", list: ["0", "1"] },
          { model: "sign8", label: "髌前囊信号异常", list: ["0", "1"] },
        ],
        ligament_tendon: [
          { model: "tendon1", label: "前交叉韧带", list: ["0", "1"] },
          { model: "tendon2", label: "后交叉韧带", list: ["0", "1"] },
          { model: "tendon3", label: "髌骨肌腱", list: ["0", "1"] },
        ],
        osteophyte: [
          { model: "region1", label: "髌骨下部", list: ["0", "1", "2", "3"] },
          { model: "region2", label: "髌骨上部", list: ["0", "1", "2", "3"] },
          { model: "region3", label: "髌骨外侧", list: ["0", "1", "2", "3"] },
          { model: "region4", label: "髌骨内侧", list: ["0", "1", "2", "3"] },
          { model: "region5", label: "胫骨外侧", list: ["0", "1", "2", "3"] },
          { model: "region6", label: "胫骨内侧", list: ["0", "1", "2", "3"] },
          {
            model: "region7",
            label: "胫骨内侧后方",
            list: ["0", "1", "2", "3"],
          },
          {
            model: "region8",
            label: "胫骨内侧中央",
            list: ["0", "1", "2", "3"],
          },
          {
            model: "region9",
            label: "胫骨内侧前方",
            list: ["0", "1", "2", "3"],
          },
          {
            model: "region10",
            label: "胫骨外侧后方",
            list: ["0", "1", "2", "3"],
          },
          {
            model: "region11",
            label: "胫骨外侧中央",
            list: ["0", "1", "2", "3"],
          },
          {
            model: "region12",
            label: "胫骨外侧中前方",
            list: ["0", "1", "2", "3"],
          },
        ],
        meniscus_position: [
          {
            model: "position1",
            label: "内侧半月板向外",
            list: ["0", "1", "2", "3"],
          },
          {
            model: "position2",
            label: "内侧半月板向前",
            list: ["0", "1", "2", "3"],
          },
          {
            model: "position3",
            label: "外侧半月板向外",
            list: ["0", "1", "2", "3"],
          },
          {
            model: "position4",
            label: "内侧半月板向前",
            list: ["0", "1", "2", "3"],
          },
        ],
        cardiac: [
          {
            model: "cardiac",
            label: "心脏射血分数",
            unit: "%",
          },
        ],
      },
    };
  },

  computed: {
    percent(){
      return Number(this.percentage.toFixed(2))
    },
    hipPercent(){
      return Number(this.hipPercentage.toFixed(2))
    }
  },
  created(){
    this.operation_id = this.$route.query.room;
    this.operation_site = this.$route.query.site;
    if(this.operation_site === "髋"){
      this.operateForm.xray = [
          {
            name: "Pauwels角",
            pre: "",
            after: "",
          },
          {
            name: "髋臼外展角",
            pre: "",
            after: "",
          },
          {
            name: "股骨颈干角",
            pre: "",
            after: "",
          },
          {
            name: "左侧股骨偏心距",
            pre: "",
            after: "",
          },
          {
            name: "右侧股骨偏心距",
            pre: "",
            after: "",
          },
          {
            name: "双下肢实际长度差",
            pre: "",
            after: "",
          },
          {
            name: "髋臼CE角",
            pre: "",
            after: "",
          },
      ]
      this.operateForm.ct = [
          {
            name: "股骨假体前倾角",
            left: "",
            right: "",
            after: "",
          },
          {
            name: "髋臼假体前倾角",
            left: "",
            right: "",
            after: "",
          },
          {
            name: "髋臼假体外展角",
            left: "",
            right: "",
            after: "",
          },
          {
            name: "股骨前倾角",
            left: "",
            right: "",
            after: "",
          },
      ]
      this.showHip = true
    }
    this.GetprecheckInfo()
  },

  methods:{

    // 获取表单请求
    async GetprecheckInfo() {
      try {
        const { data: res } = await this.$http.get(
          "/Periop/getPeriopPreop/" + this.operation_id,
        );
          console.log(res,"检查术前数据获取成功");

        if (res.status == 0) {
          this.showBtn = true
        }else if (res.status == 1) {
          let value = res.data;
            for(let key in value){
              this.operateForm[key] = value[key] ? value[key] : this.operateForm[key]
          }
          // this.operateForm = res.data
          this.showAll = true;
          this.showBtn = false;
          this.loading = false
          // console.log(res,"围手术期数据获取成功");
        }
      } catch (error) {
        // console.log(error);
      }
    },

    // 更改表单请求
    async PostprecheckdataInfo() {
      try {
        const {PPreop_id,...objRest} = this.operateForm
        const { data: res } = await this.$http.post(
          "/Periop/addPeriopPreop/" + this.operation_id,
          objRest
        );
        console.log(res, "写入成功");
        if (res.status == 1) {
         this.$message.success("修改成功")
         this.GetprecheckInfo()
         
        }
      } catch (error) {
        alert(error.message)
      }
    },

    // 去填写
    goFillin() {
      if (this.operation_site == "髋") {
        this.hipDialogVisible = true;
      }else{
        this.dialogVisible = true;
      }
    },
    toModify() {
      this.modify = !this.modify;
    },
    // 点击确定修改
    sure() {
      this.modify = !this.modify;
      this.PostprecheckdataInfo();
    },
    // 点击取消修改
    cancel() {
      this.modify = !this.modify;
    },
    // 点击填写下一步
    nextlist() {
      this.next = this.next < 13 ? this.next + 1 : 13;
      if (this.next < 13) {
        this.percentage += 7.69;
      } else {
        this.percentage = 100;
        this.nextBtn = false;
      }
    },
    // 上一步
    backlist() {
      this.next = this.next - 1 <= 1 ? 1 : this.next - 1;
      this.percentage = this.percentage > 8 ? this.percentage - 7.69 : 8;
      this.nextBtn = this.next > 12 ? false : true;
    },
    // 髋关节填写下一步
    hipnextlist() {
      this.next = this.next < 5 ? this.next + 1 : 5;
      if (this.next < 5) {
        this.hipPercentage += 20;
      } else {
        this.hipPercentage = 100;
        this.nextBtn = false;
      }
    },
    // 髋关节上一步
    hipbacklist() {
      this.next = this.next - 1 <= 1 ? 1 : this.next - 1;
      this.hipPercentage = this.hipPercentage > 5 ? this.hipPercentage - 5 : 5;
      this.nextBtn = this.next > 4 ? false : true;
    },
    // dialog确定
    closedialog() {
      this.PostprecheckdataInfo()
      this.dialogVisible = false;
      this.hipDialogVisible = false;
      this.showBtn = false;
      this.showAll = true;
    },
  }
};
</script>

<style scoped>
.precheckdata .modifyBtn{
  position: absolute;
  right:15%;
  top: 60%;
  z-index: 9999;
}
@media screen and (max-width: 600px) {
  .precheckdata .modifyBtn {
    top: 75%;
    right: 3%;
 
}
}
.precheckdata .el-progress-bar{
  margin-right: -60px !important;
}
</style>