<template>
  <el-table border :data="list" height="100%" style="width: 100%" >

    <el-table-column label="编号" width="120" >
      <template slot-scope="list"> {{ list.row.serial_number }}</template>
    </el-table-column>

    <el-table-column label="医院ID" width="80">
      <template slot-scope="list"> {{ list.row.hospital_id }}</template>
    </el-table-column>

    <el-table-column label="姓名" width="100">
      <template slot-scope="list"> {{ list.row.patients_name }}</template>
    </el-table-column>

    <el-table-column label="性别" width="80">
      <template slot-scope="list"> 
        {{ list.row.gender }}
        <i class="el-icon-male" style="color: rgb(250, 0, 0); font-weight: bold;" v-if="list.row.gender == '女'"></i>
        <i class="el-icon-female" style="color: rgb(9, 6, 204); font-weight: bold;" v-if="list.row.gender == '男'"></i>
      </template>
    </el-table-column>

    <el-table-column label="年龄" width="80">
      <template slot-scope="list"> {{ list.row.age }} 岁</template>
    </el-table-column>

    <el-table-column label="患病关节" width="100">
      <template slot-scope="list"> {{ list.row.operation_site }}</template>
    </el-table-column>

    <el-table-column label="费用类型" width="150">
      <template slot-scope="list"> {{ list.row.pay_type }}</template>
    </el-table-column>

    <el-table-column label="BMI" width="80">
      <template slot-scope="list"> 
        <el-popover trigger="hover" placement="top">
          <p>身高: {{ list.row.stature }} CM</p>
          <p>体重: {{ list.row.weight }} KG</p>
          <div slot="reference" class="name-wrapper">
            <el-tag size="medium">{{ list.row.bmi }}</el-tag>
          </div>
        </el-popover>
      </template>
    </el-table-column>

    <el-table-column fixed="right" label="操作" width="200">
      <template slot-scope="list">
        <el-button-group>
          <el-button @click="jump({a1:list.row.operation_id,a2:list.row.operation_site,isshowbreadcrumb:'2'})" type="primary" size="small">查看</el-button>
          <el-button @click="modify(list.row.operation_id)" type="info" size="small">编辑</el-button>
          <el-popconfirm
            confirm-button-text='确定'
            cancel-button-text='取消'
            icon="el-icon-info"  icon-color="red"
            title="您确定删除该病例吗？删除后无法还原"
          >
            <el-button @confirm = "dele(item.row.operation_id)" type="danger" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </el-button-group>  
      </template>        
    </el-table-column>
    
  </el-table>
</template>


<!-- <template>
  <div class="list">
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" v-for=" (item, index) in list" :key="index">
        <el-card class="box-card list-card" >
          <div slot="header" class="clearfix" >
            <span style="float: left;margin-top: 3px;">编号：{{ item.serial_number }}</span>
            <el-button-group style="float: right;" >
              <el-button type="primary" size="mini" icon="el-icon-edit-outline" @click="modify(item.operation_id)"><span  class="but_text">修改</span></el-button>
              <el-popconfirm title="您确定删除该病例吗？删除后无法还原" @confirm = "dele(item.operation_id)">
                <el-button type="danger" size="mini" icon="el-icon-delete" slot="reference"><span class="but_text">删除</span></el-button>
              </el-popconfirm>
            </el-button-group>
          </div>
          <div  class="" style="padding: 10px !important; text-align: left;">
            <p><i class="el-icon-connection" style="color: #1b72e4;" /> 医院ID：{{ item.hospital_id }}</p>
            <p>
                <i class="el-icon-user" style="color:#1b72e4;" /> 姓名：{{ item.patients_name }}
                <el-tooltip class="item" effect="dark" :content="item.gender"  placement="top">
                  <i class="el-icon-male" style="color: rgb(250, 0, 0); font-weight: bold;" v-if="item.gender == '女'"></i>
                  <i class="el-icon-female" style="color: rgb(9, 6, 204); font-weight: bold;" v-if="item.gender == '男'"></i>
                </el-tooltip>
                <el-tag type="" effect="plain" size="small" style="margin-left: 10px;">{{ item.age }} 岁</el-tag>
            </p>
            <p><i class="el-icon-warning-outline" style="color: rgb(151, 12, 12);" /> 患病关节：{{ item.operation_site }}</p>
            <p><i class="el-icon-collection-tag" style="color: rgb(3, 192, 19);" /> 费用类型：{{ item.pay_type }}</p>
            <p><i class="el-icon-coin" style="color: rgba(224, 191, 1, 0.829);" /> 身高体重：{{ item.stature }}CM | {{ item.weight }}KG（BMI：{{ item.bmi }}）</p>
          </div>
          <div class="bottom " style="padding-top: 10px;">
            <el-button-group>
              
              <el-button type="primary" icon="el-icon-document-remove" size="small" :disabled="item.patients_name==null" @click="jump({a1:item.operation_id,a2:item.operation_site,isshowbreadcrumb:'2'})">术前信息</el-button>
              <el-button type="primary" icon="el-icon-document" size="small" :disabled="item.patients_name==null" @click="jump({a1:item.operation_id,a2:item.operation_site,isshowbreadcrumb:'3'})">检验信息</el-button>
              <el-button type="primary" icon="el-icon-tickets" size="small" :disabled="item.patients_name==null" @click="jump({a1:item.operation_id,a2:item.operation_site,isshowbreadcrumb:'1'})">手术详情</el-button>
              <el-button type="primary" icon="el-icon-document-copy" size="small" :disabled="item.patients_name==null" @click="jump({a1:item.operation_id,a2:item.operation_site,isshowbreadcrumb:'4'})">检查信息</el-button>
            </el-button-group>
          </div>
        </el-card>
      </el-col>
    </el-row> 
  </div>
</template> -->


<script>
import EventBus from "@/util/EventBus";
// import modify from "../modify/modify.vue";
export default {
  data() {
    return {
      diawid:'30%',
      columns:2,
      list: [],
      gridData:[],
      dialogVisible:false,
      patientInfo1:false,
      Surgical:[
        {type:"input",label:"医院ID",dw:''},
        {type:"time",label:"手术日期",dw:''},
        {type:"select",label:"费用类型",
        dw:[
          {label:"省内异地医保",value:'省内异地医保'},
          {label:"跨省异地医保",value:'跨省异地医保'},
          {label:"广州医保",value:'广州医保'},
          {label:"居民医保",value:'居民医保'},
          {label:"市直医保",value:'市直医保'},
          {label:"外地医保",value:'外地医保'},
          {label:"自费",value:'自费'},
          {label:"商业医保",value:'商业医保'},
          {label:"职工医保",value:'职工医保'},
          {label:"公医",value:'公医'},
          {label:"其他",value:'其他'},
        ]
      },
        {type:"checkbox",label:"手术名称",dw:["膝关节内侧单髁置换","膝关节置换","髋关节置换"]},
        {type:"checkbox",label:"手术关节",dw:['髋',"左膝","右膝"]},
        {type:"input",label:"体重",dw:'KG'},
        {type:"input",label:"身高",dw:'cm'},
      ],
      value:[
        {label:''},
        {label:''},
        {label:''},
        {label:[]},
        {label:[]},
        {label:''},
        {label:''},
      ],
      // modifydialog:false,
      // modifyvalue:[
      //   { hospital_id: "" },
      //   { patient__patients_name: "" },
      //   { id_card: "" },
      //   { phone_family: "" },
      //   { patient__phone: "" },
      //   { nation: "" },
      //   { native: [] },
      //   { pay_type: [] },
      //   { patient__gender: [] },
      //   { marital_status: [] },
      //   { work_unit: "" },
      //   { occupation: "" },
      //   { patient__birth_date: "" },
      //   { birth_weather: "" },
      //   { birth_seasons: [] },
      //   { hospital_date: [] },
      //   {home_address:{add:'',value:[]}},
      //   {operation_id:''}
      // ],
      modifysubmitvalue:[],
      numbering:''
    };
  },
  watch:{
  
  },
  components:{
 
  },
  filters:{
    filterphone(value){
      let arr = []
      for(let key in value){
        if(value[key] !=''){
           arr.push(value[key])
        }
      }
      return arr.toString()
    }
  },
  mounted() {
    let wid = window.innerWidth
    // this.modifydialog=false
    this.diawid = wid<=480 ? "90%":wid>=1200 ? "40%":"80%"
    this.columns = wid<=480 ? 1:wid>=1200 ? 2:2
    this.Currentsurgicalinformation();
    EventBus.$on("crush",()=>{
    this.Currentsurgicalinformation()
    })
    EventBus.$on('querymethod',(value)=>{
      this.Currentsurgicalinformation(value)
    })
  },
  methods: {
    toinfo(value){
      this.$store.commit("showinputmethod",false)

      this.$router.push({
              path:'/oneselfinfomation',
              query: {
                 room: value
                    },
            })
            this.$store.commit("changeshowbtn", "false");
      this.$store.commit("changemedicalname", "完善患者详细信息");
    },
   async confirm(){
        const {data:res} = await this.$http.post("/operation/addOperationInfo",{
          "hospital_id":this.value[0].label,
          "hospital_date":this.value[1].label,
          "pay_type":this.value[2].label,
          "operation_name":this.value[3].label[0],
          "operation_site":this.value[4].label[0],
          "weight":this.value[5].label,
          "stature":this.value[6].label,
        })
        if(res.status =="1"){
          if(res.data.hospital_id){
              this.Currentsurgicalinformation()
          }else{
            this.$router.push({
              path:'/oneselfinfomation',
              query: {
                 room: this.value[0].label,
                    },
            })
          }
        }else{
      this.$message.error(res.message.Instructions)
    }
      },
    addSurgical(){
      this.dialogVisible = true
    },
    jump(value) {
      let jumparr = ['/insurgery','/presentHistory','/preinspdata','/preCheckData']
      this.$store.commit("changeshowbtn", "false");
      this.$store.commit("changemedicalname", "病历详细信息");
      this.$store.commit("showinputmethod",false)
      this.$store.commit('changelevel1',0)
      this.$store.commit('changelevel2',0)
      this.$store.commit('changebreadcrumb',value.isshowbreadcrumb)
      this.$router.push({
        path: jumparr[value.isshowbreadcrumb-1],
        query: {
          room: value.a1,
          site: value.a2
        },
      });
      // console.log(value)
    },

    async dele(value) {
      const { data: res } = await this.$http.get('/Operation/deleOperationInfo/'+ value);
      // console.log(res.data);
      if(res.status){
        this.$notify({
          title: '成功',
          message: '成功删除该数据',
          type: 'success',
          duration: 2000
        });
        this.Currentsurgicalinformation()
      }else{
        this.$notify.error({
          title: '失败',
          message: '删除数据时，出现错误',
          duration: 2000
        });
      }
    },
    // 获取当前登录者的手术信息
    async Currentsurgicalinformation(value) {
      let resvalue = value ? value:''
      // console.log(resvalue,'7898798')
      try{
      const { data: res } = await this.$http.get("/operation/getOperationInfo",{
        params:{
          'searchinfo':resvalue
        }
      }
      );
      // console.log(res)
      if ((res.status = "获取成功")) {
        this.list = res.data;
      }
    }catch(e){

    }
    },
    modify(value){
      this.$store.commit("changeshowbtn", "false");
      this.$store.commit("changemedicalname", "修改患者信息");
      this.$store.commit("showinputmethod",'false')
      this.$router.push({
              path:'/modify',
              query: {
                 room: value
                    },
            })
      // this.modifydialog=true
      // console.log(value)
      // setTimeout(()=>{
      // let val=value
      //       for(let i=0;i<this.modifyvalue.length;i++){
      //         let obj = this.modifyvalue[i]
      //         for(let key in obj){
      //         for(let key2 in val){
      //           if(key == key2){
      //             let determine = this.modifyvalue[i][key]
      //             if(!Array.isArray(determine) && typeof determine ==='object'){
      //               this.modifyvalue[i][key].add = val[key].add
      //               this.modifyvalue[i][key].value = val[key].value
      //             }else{
      //               if(typeof val[key] === 'object' && val[key]!=null){
                     
      //                 this.modifyvalue[i][key] =Object.values(val[key])
      //               }else{
      //                 this.modifyvalue[i][key] = val[key] == null ? '无':val[key]
      //               }
                   
      //             }
      //           }
      //         }
      //         }
      //       }
      // },100)
    },
    // getmodify(value){
    //   this.modifysubmitvalue=[]
    //   this.modifysubmitvalue = value
    // },
  // async  tosubmit(){
  //     const {data:res} =await this.$http.put('/patient/editPatientInfo',{
  //       "hospital_id":this.modifysubmitvalue[0].value,
  //           "patients_name":this.modifysubmitvalue[1].value,
  //           "id_card":this.modifysubmitvalue[2].value,
  //           "phone_family":this.modifysubmitvalue[3].value,
  //           "phone":this.modifysubmitvalue[4].value,
  //           "nation":this.modifysubmitvalue[5].value,
  //           "native":this.modifysubmitvalue[6].value,
  //           "pay_type":this.modifysubmitvalue[7].value,
  //           "gender":this.modifysubmitvalue[8].value[0],
  //           "marital_status":this.modifysubmitvalue[9].value,
  //           "work_unit":this.modifysubmitvalue[10].value,
  //           "occupation":this.modifysubmitvalue[11].value,
  //           "birth_date":this.modifysubmitvalue[12].value,
  //           "birth_weather":this.modifysubmitvalue[13].value,
  //           "birth_seasons":this.modifysubmitvalue[14].value[0],
  //           "hospital_date":this.modifysubmitvalue[15].value,
  //           "home_address":this.modifysubmitvalue[16],
  //           "operation_id":this.modifysubmitvalue[17].value,
            
  //         })
  //         console.log(res)
  //         if(res.status=="1"){
  //           this.Currentsurgicalinformation()
  //           // this.modifydialog=false
  //         }else{
  //           this.$message.error('提交出错，请联系管理员')
  //         }
  //   },
    // 获取患者信息
    async patientInfo(value) {
      this.gridData = value;
      this.patientInfo1 = true;
    },

  },
};
</script>

<style >
.list {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  flex-wrap: wrap;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
.list-card{
  margin-top: 15px;

}
.listcontainer {
  box-sizing: border-box;
  min-height: 130px;
  width: 100%;
  background: #f8fafc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid #e6e7e9;
}
.listtop {
  width: 100%;
  height: 45px;
  /* background-color: antiquewhite; */
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e7e9;
  justify-content: space-between;
  padding: 0 8px;
}
.listname {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 8px;
}
.bottom {
  /* height: 56px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid #e6e7e9;
}
.bottom span{
  width: fit-content !important;
}
.bottom .el-button{
  margin-left: 0px !important;
}
.listnamelist {
  /* flex: 1; */
  width: fit-content;
  /* padding: 0px 8px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 5px 0px;
}
.listnamelist label {
  margin-right: 8px;
}

.el-row {
  flex: 1;
}
.el-card__body {
  padding: 10px !important;
}
.addSurgical{
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dw{
  width:40px;
}
.huanzexinxi .el-dialog{
  box-shadow: 0 1px 3px rgb(255, 255, 255) !important;
}
</style>